<template>
  <div>
    <hidden-setting></hidden-setting>
  </div>
</template>

<script>
import HiddenSetting from '../../common-config/hidden-setting';
export default {
  components: {
    HiddenSetting
  }
};
</script>
